define("discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-llm-selector", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/runloop", "@ember/service", "discourse/lib/key-value-store", "select-kit/components/dropdown-select-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _runloop, _service, _keyValueStore, _dropdownSelectBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function isBotMessage(composer, currentUser) {
    if (composer && composer.targetRecipients && currentUser.ai_enabled_chat_bots) {
      const reciepients = composer.targetRecipients.split(",");
      return currentUser.ai_enabled_chat_bots.filter(bot => !bot.is_persona).any(bot => reciepients.any(username => username === bot.username));
    }
    return false;
  }
  class BotSelector extends _component.default {
    static shouldRender(args, container) {
      return container?.currentUser?.ai_enabled_personas && isBotMessage(args.model, container.currentUser);
    }
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "llm", [_tracking.tracked]))();
    #llm = (() => (dt7948.i(this, "llm"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "allowLLMSelector", [_tracking.tracked], function () {
      return true;
    }))();
    #allowLLMSelector = (() => (dt7948.i(this, "allowLLMSelector"), void 0))();
    STORE_NAMESPACE = "discourse_ai_persona_selector_";
    LLM_STORE_NAMESPACE = "discourse_ai_llm_selector_";
    preferredPersonaStore = (() => new _keyValueStore.default(this.STORE_NAMESPACE))();
    preferredLlmStore = (() => new _keyValueStore.default(this.LLM_STORE_NAMESPACE))();
    constructor() {
      super(...arguments);
      if (this.botOptions && this.composer) {
        let personaId = this.preferredPersonaStore.getObject("id");
        this._value = this.botOptions[0].id;
        if (personaId) {
          personaId = parseInt(personaId, 10);
          if (this.botOptions.any(bot => bot.id === personaId)) {
            this._value = personaId;
          }
        }
        this.composer.metaData = {
          ai_persona_id: this._value
        };
        this.setAllowLLMSelector();
        let llm = this.preferredLlmStore.getObject("id");
        llm = llm || this.llmOptions[0].id;
        if (llm) {
          (0, _runloop.next)(() => {
            this.currentLlm = llm;
          });
        }
      }
    }
    get composer() {
      return this.args?.outletArgs?.model;
    }
    get botOptions() {
      if (this.currentUser.ai_enabled_personas) {
        return this.currentUser.ai_enabled_personas.map(persona => {
          return {
            id: persona.id,
            name: persona.name,
            description: persona.description
          };
        });
      }
    }
    get filterable() {
      return this.botOptions.length > 4;
    }
    get value() {
      return this._value;
    }
    set value(newValue) {
      this._value = newValue;
      this.preferredPersonaStore.setObject({
        key: "id",
        value: newValue
      });
      this.composer.metaData = {
        ai_persona_id: newValue
      };
      this.setAllowLLMSelector();
    }
    setAllowLLMSelector() {
      const persona = this.currentUser.ai_enabled_personas.find(innerPersona => innerPersona.id === this._value);
      this.allowLLMSelector = !persona?.force_default_llm;
    }
    get currentLlm() {
      return this.llm;
    }
    set currentLlm(newValue) {
      this.llm = newValue;
      const botUsername = this.currentUser.ai_enabled_chat_bots.find(bot => bot.model_name === this.llm).username;
      this.preferredLlmStore.setObject({
        key: "id",
        value: newValue
      });
      if (this.allowLLMSelector) {
        this.composer.set("targetRecipients", botUsername);
      } else {
        const persona = this.currentUser.ai_enabled_personas.find(innerPersona => innerPersona.id === this._value);
        this.composer.set("targetRecipients", persona.username || "");
      }
    }
    get llmOptions() {
      const availableBots = this.currentUser.ai_enabled_chat_bots.filter(bot => !bot.is_persona).filter(Boolean);
      return availableBots.map(bot => {
        return {
          id: bot.model_name,
          name: bot.display_name
        };
      });
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="persona-llm-selector">
          <div class="gpt-persona">
            <DropdownSelectBox
              class="persona-llm-selector__persona-dropdown"
              @value={{this.value}}
              @content={{this.botOptions}}
              @options={{hash icon="robot" filterable=this.filterable}}
            />
          </div>
          {{#if this.allowLLMSelector}}
            <div class="llm-selector">
              <DropdownSelectBox
                class="persona-llm-selector__llm-dropdown"
                @value={{this.currentLlm}}
                @content={{this.llmOptions}}
                @options={{hash icon="globe"}}
              />
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "3PhHsEc5",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"persona-llm-selector\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"gpt-persona\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"persona-llm-selector__persona-dropdown\"]],[[\"@value\",\"@content\",\"@options\"],[[30,0,[\"value\"]],[30,0,[\"botOptions\"]],[28,[32,1],null,[[\"icon\",\"filterable\"],[\"robot\",[30,0,[\"filterable\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"allowLLMSelector\"]],[[[1,\"        \"],[10,0],[14,0,\"llm-selector\"],[12],[1,\"\\n          \"],[8,[32,0],[[24,0,\"persona-llm-selector__llm-dropdown\"]],[[\"@value\",\"@content\",\"@options\"],[[30,0,[\"currentLlm\"]],[30,0,[\"llmOptions\"]],[28,[32,1],null,[[\"icon\"],[\"globe\"]]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-llm-selector.js",
      "scope": () => [_dropdownSelectBox.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BotSelector;
});